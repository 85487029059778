// content
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { GetUserForm } from "../../services/srvc-utilities";
import {
  GetLocalUser,
  GetLocalBusiness,
  GetAuthUser,
} from "../../services/srvc-auth-local";
import { TeamMemberList } from "../../services/srvc-users-creators-realm";

const listactions =
  require("../../data.static/data-user-overview-actions.json").data;

export default function OverviewActionModule() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const auth = GetAuthUser();
  const asset = usxx === "user" ? usrx : temx;
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();
  const [ownerEmail, setOwnerEmail] = useState();

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const result = listactions.filter((x) => x.user.includes(asset.role));
        setData(result);

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const result = await TeamMemberList({
          data: {
            team: asset.item,
          },
          service: asset?.creds?.service,
          client: asset?.creds?.client,
          secret: asset?.creds?.secret,
        });

        if (result.stat) {
          let email = result.data.list.filter((obj) => obj.role === "owner");
          setOwnerEmail(email);
        }

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  const handleClick = (live, link) => {
    if (live) navigate(`/${asset.role}/${link}`);
  };

  if (loader) return <></>;

  return (
    <>
      {/* {auth?.user == ownerEmail[0]?.user?.mail && ( */}
        <div className="back-color-wite rounded-xd p-1">
          {data &&
            data.map((item, i) =>
              item.actv ? (
                <div
                  className={`d-flex p-2 align-middle 
          ${item.live ? "cursor hidark rounded-wd" : "text-color-tint"}`}
                  style={{ height: "2.4rem" }}
                  key={i}
                  onClick={() => handleClick(item.live, item.link)}
                >
                  <div className="">
                    <i
                      className={`m-0 p-0 ${item.icon} `}
                      style={{ fontSize: "1.35rem" }}
                    ></i>
                  </div>
                  <div className="ms-2">
                    <p className={`m-0 p-0 text-nowrap`}>
                      <span className="text-small align-middle">
                        {item.name}
                      </span>
                    </p>
                  </div>
                  <div className="ms-auto ">
                    <i
                      className={`m-0 p-0 bx bx-chevron-right`}
                      style={{ fontSize: "1.35rem" }}
                    ></i>
                  </div>
                </div>
              ) : (
                ""
              )
            )}
        </div>
      {/* )} */}
    </>
  );
}
