// main
import { Helmet } from "react-helmet";

import ContentFormat from "../content/webz/content-format-xx";
import WebbHeader from "../content/webz/webb-header-xx";
import WebbFooterMobile from "../content/webz/webb-footer-mobile";

import WebbDividerMedium from "../content/webx/webb-divider-md";
import WebbDividerSmall from "../content/webx/webb-divider-sm";
import WebbLoaderSmall from "../content/webx/webb-loader-sm";

import AssetsCreateModule from "../content/assets/assets-create";
import AssetsDataCollectModule from "../content/assets/assets-data-collect";

export default function AssetsCollectData() {
  const metadata = {
    name: "Collect Data",
    banner: {
      link: "https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900",
    },
  };

  return (
    <>
      <Helmet>
        <title>
          {metadata.name}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_NAME}
          {" • "}
          {process.env.REACT_APP_WEBB_SITE_LINE}
        </title>
        <link rel="canonical" href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        header={{
          size: "xtra",
          show: true,
          data: (
            <>
              <div className="">
                <WebbHeader
                  data={{ name: metadata.name, home: "/", link: "home" }}
                />
              </div>
            </>
          ),
        }}
        media={{ size: "xtra", show: false, data: <></> }}
        content={{
          size: "medium",
          show: true,
          data: (
            <>
              <WebbDividerSmall />
              <h2 className="text-normal m-0 ms-3 d-none">Collect Data</h2>
              <div className="">
                <AssetsDataCollectModule />
              </div>

              <WebbDividerMedium />
              <div className="container-fluid"></div>
              <WebbDividerMedium />

              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          ),
        }}
        footer={{
          size: "medium",
          show: true,
          data: (
            <>
              <div className="text-center back-color-lite">
                <WebbFooterMobile />
              </div>
            </>
          ),
        }}
      ></ContentFormat>
    </>
  );
}
