import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import WebbDividerSmall from "../webx/webb-divider-sm";
import FormNeeded from "../webx/form-needed";

import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";

import { CollectUserData } from "../../services/srvc-assets-units-realm";

const listFormat = require("../../data.static/data-asset-formats.json").data;

export default function AssetsDataCollectModule() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;

  const { id, type } = useParams();

  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [done, setDone] = useState(false);
  const [memo, setMemo] = useState("");

  const [data, setData] = useState({
    mxcount: "",
    mxrate: "",
    mxticker: "INR",
    txminter: "",
    txminterstatus: false,
    txunit: "",
    txcount: "1",
  });

  // Single state to handle both selection and mandatory status
  const [cardStates, setCardStates] = useState({});

  const handleCardClick = (item) => {
    setCardStates((prev) => ({
      ...prev,
      [item.code]: {
        selected: !prev[item.code]?.selected,
        mandatory: prev[item.code]?.mandatory || false,
      },
    }));
  };

  const handleCheckboxChange = (item) => {
    setCardStates((prev) => ({
      ...prev,
      [item.code]: {
        selected: prev[item.code]?.selected || false,
        mandatory: !prev[item.code]?.mandatory,
      },
    }));
  };

  const handleSubmit = async () => {
    setLoader(true);
    setSubmit(true);
    setMemo("Please wait...");

    const selectedData = Object.entries(cardStates)
      .filter(([_, value]) => value.selected)
      .map(([key, value]) => ({
        value: key,
        mandatory: value.mandatory,
      }));

    var objArr = [];
    for (var i = 0; i < selectedData.length; i++) {
      objArr.push({ ...selectedData[i], type: "string" });
    }

    const datx = {
      unit: id,
      creator: asset.item,
      domain: type,
      vars: objArr,
    };

    const result = await CollectUserData({
      data: datx,
      service: asset?.creds?.service,
      client: asset?.creds?.client,
      secret: asset?.creds?.secret,
      type: type,
    });

    if (result.stat) {
      setDone(true);
      setMemo("Format Created Successfully");
    } else {
      setDone(false);
      setSubmit(false);
      setMemo("Format Creation failed");
    }

    setLoader(false);
  };

  const list = [
    {
      name: "Name",
      memo: "varified by Aadhaar or other authorized Identity Document",
      mime: "text",
      code: "name",
      item: "9829dbd934a94124bec8a8eedb6650189",
    },
    {
      name: "Email Address",
      memo: "to send important updates and notifications",
      mime: "mail",
      code: "mail",
      item: "9ebe20ef5cac41c5b17bce007a7b04be8",
    },
    {
      name: "Mobile Number",
      memo: "for quick contact and verification",
      mime: "text",
      code: "mobile",
      item: "94c1f4aa2c4f4de9ad99ae714d5cbff70",
    },
    {
      name: "Address",
      memo: "as entered by user",
      mime: "text",
      code: "address",
      item: "5aff554784424cde8c1203d583a1f4771",
    },
    {
      name: "LinkedIn",
      memo: "as entered by user",
      mime: "text",
      code: "linkedin",
      item: "5aff554784424cde8c1203d583a1f4772",
    },
  ];

  return (
    <>
      <div className="mx-3">
        <h2 className="text-normal m-0">Data Formats</h2>
        <WebbDividerSmall />
      </div>
      <div className="back-color-wite p-3 rounded-xd">
        <p className="text-bold py-2">
          Please Choose following data points, which you want to be submitted by
          users before token purchase
        </p>
        <div className="row row-cols-1 row-cols-md-1 ">
          {list.map((item, i) => {
            const isSelected = cardStates[item.code]?.selected || false;
            const isMandatory = cardStates[item.code]?.mandatory || false;
            return (
              <div className="pb-2" key={i}>
                <div
                  className={`p-2 px-3 rounded-1 border cursor ${
                    isSelected ? "back-color-next text-color-wite" : ""
                  }`}
                  onClick={() => handleCardClick(item)}
                >
                  <p className="m-0">
                    <strong>{item.name}</strong> {item.memo}
                  </p>
                </div>
                <div className="form-check ">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`mandatoryCheckbox-${i}`}
                    checked={isMandatory}
                    onChange={() => handleCheckboxChange(item)}
                  />
                  <label
                    className="form-check-label text-mini"
                    htmlFor={`mandatoryCheckbox-${i}`}
                  >
                    Mandatory
                  </label>
                </div>
              </div>
            );
          })}
        </div>

        <div className="mt-3">
          <p className={loader ? "text-danger" : "text-success"}>
           {memo}
          </p>
        </div>

        {!loader && !done && (
          <div className="d-flex modal-footer border-none mt-4 mb-3">
            <button
              className="btn btn-outline-secondary btn-sm text-small rounded-xx"
              // data-bs-dismiss="modal"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <button
              className="btn btn-primary btn-sm text-small rounded-xx ms-auto"
              onClick={handleSubmit}
              disabled={
                !Object.values(cardStates).some((state) => state.selected)
              }
            >
              Submit
            </button>
          </div>
        )}
      </div>
    </>
  );
}
