import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import { GetUserForm } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import FormNeeded from "../webx/form-needed";
import WebbDividerSmall from "../webx/webb-divider-sm";
import {
  AssetUnitDetails,
  unitsBook,
  unitsLive,
  unitsMint,
  unitsResale,
  unitsScan,
  unitsShare,
  unitsShow,
} from "../../services/srvc-assets-units-realm";
import { type } from "@testing-library/user-event/dist/type";
// import { useParams } from 'react-router-dom'

// import { AssetDelete, AssetsBook, AssetsDetail, AssetsLive, AssetsScan, AssetsShow } from "../../services/srvc-assets-realm";

// const listactions = require("../../data.static/data-asset-details.json").data;

const listactions = [
  { name: "Live", code: "live", status: false, active: true },
  { name: "Show", code: "show", status: false, active: true },

  { name: "Mint", code: "mint", status: false, active: false },

  { name: "Scan", code: "scan", status: false, active: true },
  { name: "Sale", code: "sale", status: false, active: false },
  { name: "Book", code: "book", status: false, active: true },
  { name: "Burned", code: "burn", status: false, active: false },

  { name: "Feature", code: "feat", status: false, active: false },
  { name: "Search", code: "srch", status: false, active: false },
];

export default function AssetOptionModule(props) {
  const navigate = useNavigate();

  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const { item } = useParams();
  const [loader, setLoader] = useState(true);
  const [data, setData] = useState();
  const [assetId, setAssetId] = useState("");

  const { id ,type} = useParams();
 

  const [stat, SetStat] = useState({
    mint: false,
    scan: false,
    live: false,
    book: false,
    show: false,
    resale: false,
    share: false
  });
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedAssetToDelete, setSelectedAssetToDelete] = useState(null);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        // const result = listactions.filter((x) => x.user.includes(asset.role));
        // setData(result);

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  // useEffect(() => {
  //   setLoader(true);
  //   const fetchData = async () => {
  //     var res = await AssetsDetail({
  //       data: {
  //         item: item,
  //       },
  //     });
  //     SetStat(res?.data?.stat);
  //     setLoader(false);
  //   };

  //   fetchData();
  // }, []);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);
        const datx = { item: id, creator: asset.item };
        const result = await AssetUnitDetails({
          data: datx,
          service: asset?.creds?.service,
          client: asset?.creds?.client,
          secret: asset?.creds?.secret,
          type: type
        });
        // console.log (result)

        // console.log(result)
        if (result) {
          SetStat(result?.data?.status);
          setAssetId(result?.data?.asset?.item);
          // send data
          // props.data(result.data);
        }

        setLoader(false);
      };
      fetchData();
    } else {
    }
  }, []);

  const setMint = async (status) => {
    var res = await unitsMint({
      data: {
        item: id,
        status: status,
      },
      service: asset?.creds?.service,
      client: asset?.creds?.client,
      secret: asset?.creds?.secret,
      type: type,
    });
  };

  const setLive = async (status) => {
    var res = await unitsLive({
      data: {
        item: id,
        status: status,
      },
      service: asset?.creds?.service,
      client: asset?.creds?.client,
      secret: asset?.creds?.secret,
      type: type,
    });
  };

  const setBook = async (status) => {
    var res = await unitsBook({
      data: {
        item: id,
        status: status,
      },
      service: asset?.creds?.service,
      client: asset?.creds?.client,
      secret: asset?.creds?.secret,
      type: type,
    });
  };

  const setScan = async (status) => {
    var res = await unitsScan({
      data: {
        item: id,
        status: status,
      },
      service: asset?.creds?.service,
      client: asset?.creds?.client,
      secret: asset?.creds?.secret,
      type: type,
    });
  };

  const setShow = async (status) => {
    var res = await unitsShow({
      data: {
        asset: assetId,
        unit: id,
        status: status,
      },
      service: asset?.creds?.service,
      client: asset?.creds?.client,
      secret: asset?.creds?.secret,
      type: type,
    });
  };

   const setResale = async (status) => {
     var res = await unitsResale({
       data: {
         item: id,
         status: status,
       },
       service: asset?.creds?.service,
       client: asset?.creds?.client,
       secret: asset?.creds?.secret,
       type: type,
     });
  };
  
   const setShare = async (status) => {
     var res = await unitsShare({
       data: {
         item: id,
         status: status,
       },
       service: asset?.creds?.service,
       client: asset?.creds?.client,
       secret: asset?.creds?.secret,
       type: type,
     });
   };

  const deleteData = async () => {
    // var res = await AssetDelete({
    //   data: {
    //     item: item,
    //   },
    // service: asset?.creds?.service,client:asset?.creds?.client,secret:asset?.creds?.secret

    // });
    // if(res.stat){
    //   navigate(`/team/assets`)
    // }
    alert("Data deleted");
    setShowDeletePopup(false);
  };

  const handleClick = (items) => {
    // props.handleSelect(item);
    if (items.name == "Delete Assets") {
      setSelectedAssetToDelete(item); // store the selected asset to delete
      setShowDeletePopup(true); // Open the delete confirmation modal
    } else {
      if (items.live) navigate(`/${asset.role}/${items.link}/${item}`);
    }
  };

  const handleCheckboxChange = (key) => {
    let newData;

    if (key === "mint") {
      newData = { ...stat, mint: !stat.mint };
      setMint(!stat.mint);
    } else if (key === "scan") {
      newData = { ...stat, scan: !stat.scan };
      setScan(!stat.scan);
    } else if (key === "live") {
      newData = { ...stat, live: !stat.live };
      setLive(!stat.live);
    } else if (key === "book") {
      newData = { ...stat, book: !stat.book };
      setBook(!stat.book);
    } else if (key === "show") {
      newData = { ...stat, show: !stat.show };
      setShow(stat.show);
    } else if (key === "resale") {
      newData = { ...stat, resale: !stat.resale };
      setResale(stat.resale);
    } else if (key === "share") {
      newData = { ...stat, share: !stat.share };
      setShare(stat.share);
    }

    SetStat(newData);
  };

  const confirmDelete = () => {
    deleteData();
  };

  const cancelDelete = () => {
    setShowDeletePopup(false);
  };

  // console.log(stat)

  return (
    <>
      <div className="back-color-wite p-1 rounded-xd d-none">
        {data &&
          data.map((item, i) =>
            item.actv ? (
              <div
                className={`d-flex p-2 align-middle 
          ${item?.live ? "cursor hidark rounded-wd" : "text-color-tint"}`}
                style={{ height: "2.4rem" }}
                key={i}
                onClick={() => handleClick(item)}
              >
                <div className="">
                  <i
                    className={`m-0 p-0 ${item?.icon} `}
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                </div>
                <div className="ms-2">
                  <p className={`m-0 p-0 text-nowrap`}>
                    <span className="text-small align-middle">
                      {item?.name}
                    </span>
                  </p>
                </div>
                <div className="ms-auto ">
                  <i
                    className={`m-0 p-0 bx bx-chevron-right`}
                    style={{ fontSize: "1.5rem" }}
                  ></i>
                </div>
              </div>
            ) : (
              ""
            )
          )}
      </div>
      <WebbDividerSmall />

      <div className="back-color-wite p-2 px-3 rounded-xd">
        <div className="">
          {/* <label className="form-label text-small">
            Live <FormNeeded />
          </label> */}
          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Live</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={stat?.live}
                onChange={() => handleCheckboxChange("live")}
              ></input>
            </div>
          </div>

          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Mint</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={stat?.mint}
                onChange={() => handleCheckboxChange("mint")}
              ></input>
            </div>
          </div>

          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Enable Scan</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={stat?.scan}
                onChange={() => handleCheckboxChange("scan")}
              ></input>
            </div>
          </div>

          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Book</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={stat?.book}
                onChange={() => handleCheckboxChange("book")}
              ></input>
            </div>
          </div>

          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Enable Resale</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={stat?.resale}
                onChange={() => handleCheckboxChange("resale")}
              ></input>
            </div>
          </div>

          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Enable Social Share</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={stat?.share}
                onChange={() => handleCheckboxChange("share")}
              ></input>
            </div>
          </div>

          <div className="d-flex form-check form-switch m-0 p-0 mb-2">
            <div className="">
              <p className="m-0 p-0">Discover</p>
            </div>
            <div className="ms-auto">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                style={{ height: "1.2rem", width: "2rem" }}
                checked={stat?.show}
                onChange={() => handleCheckboxChange("show")}
              ></input>
            </div>
          </div>
        </div>
      </div>

      {showDeletePopup && (
        <div
          className="popup"
          style={{
            height: "100%",
            width: "100%",
            position: "fixed",
            top: "0px",
            left: "0px",
            backdropFilter: "brightness(0.5)",
          }}
        >
          <div
            className="popup-content p-4 border rounded"
            style={{
              position: "absolute",
              top: "30%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "white",
            }}
          >
            <h3 className=" text-center">Confirm Deletion</h3>
            <p>Are you sure you want to delete this asset?</p>
            <div className="popup-buttons  d-flex gap-3 justify-content-between">
              <button
                className="btn btn-primary rounded-xx text-small"
                onClick={cancelDelete}
              >
                Cancel
              </button>
              <button
                className="btn btn-danger rounded-xx text-small"
                onClick={confirmDelete}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
