const initialState = {
  events: true,
  academics: false,
  membership: false,
  awards: false,
  work: false,
  social: false,
  all: false,
  live: true,
  draft: false,
  deleted: false,
  search: "",
  searchButton: false,
  page: 1,
};

const assetReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_ASSET_DATA":
      return {
        ...state,
        ...action.payload,
      };
    case "UPDATE_ASSETSEARCH_TERM":
      return {
        ...state,
        search: action.payload,
      };
    case "UPDATE_ASSETSEARCH_BUTTON":
      return {
        ...state,
        searchButton: action.payload,
      };
    case "UPDATE_ASSET_PAGE":
      return {
        ...state,
        page: action.payload,
      };
    default:
      return state;
  }
};

export default assetReducer;
