// transfers
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { GetUserForm, NumberFormat } from "../../services/srvc-utilities";
import { GetLocalUser, GetLocalBusiness } from "../../services/srvc-auth-local";
import FileCreateCX from "../../services/srvc-filecreate-cweb-xx";
import { TeamAccountsLogoUpload } from "../../services/srvc-accounts-realm";

export default function TeamAccountLogoModule() {
  const usxx = GetUserForm();
  const usrx = GetLocalUser();
  const temx = GetLocalBusiness();
  const asset = usxx === "user" ? usrx : temx;
  const [logo, setLogo] = useState({ link: "******", mime: "******" });
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handleMediaBanner = async (filedata) => {
    // console.log (filedata);
    var mediax = { link: filedata?.file.link, mime: filedata?.file?.mime };
    setLogo((logo) => mediax);
  };

  console.log("temx", temx);

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true);

        const datx = {
          team: temx.item,
          avatar: { link: logo.link },
        };
        const result = await TeamAccountsLogoUpload({
          data: datx,
          service: asset?.creds?.service,
          client: asset?.creds?.client,
          secret: asset?.creds?.secret,
        });
        if (result.stat) {
          console.log("Logo result", result);
        }
        setLoader(false);
      };
      if (logo.link != "") {
        fetchData();
      }
    } else {
    }
  }, [logo]);

  // if (loader) return <></>

  return (
    <>
      {/* info */}

      <div className="back-color-wite p-3 rounded-xd">
        <div className="mb-3">
          <label className="form-label text-small">Add Logo</label>
          <FileCreateCX size="media-standard" media={handleMediaBanner} />
        </div>
      </div>
    </>
  );
}
