// main
import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import ContentFormat from '../content/webz/content-format-xv'
import WebbIcon from '../content/webx/webb-icon'
import WebbHeader from '../content/webz/webb-header-navs-xv'
import UserAvatar from '../content/webx/user-avatar'
import WebbFooterMobile from '../content/webz/webb-footer-mobile'

import WebbDividerMedium from '../content/webx/webb-divider-md'
import WebbDividerSmall from '../content/webx/webb-divider-sm'
import WebbLoaderSmall from '../content/webx/webb-loader-sm'

import { GetUserForm } from '../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../services/srvc-auth-local'

import UnitHeaderModule from '../content/assets.unit/unitx-header'
import UnitStatisticsModule from '../content/assets.unit/unitx-statistics'

import UnitRatesModule from '../content/assets.unit/unitx-rates'
import UnitTransfersListModule from '../content/assets.unit/unitx-transfers'

import UnitActionsModule from '../content/assets.unit/unitx-actions'
import UnitStatusModule from '../content/assets.unit/unitx-status'
import UnitCodeModule from '../content/assets.unit/unitx-code'
import UnitTransfersDataListModule from '../content/assets.unit/unitx-data-collected'

export default function AssetUnitDetails () {
  
  const metadata = {
    name: 'Asset Details',
    banner: {
      link: 'https://img.freepik.com/premium-vector/futuristic-vector-hexagon-wave-dark-cyberspace-abstract-wave-with-dots-line-white-moving-particles-background_744733-97.jpg?w=900'
    }
  }

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const { id ,type} = useParams()
  const [data, setData] = useState()
  const [search, setSearch] = useState()

  const [transferType, setTransferType] = useState("token")

  const handleData = async (item) => {
    setData(item)
  }

  const handleSearch = async (item) => {}

  return (
    <>
      <Helmet>
        <title>{metadata.name}{' • '}{process.env.REACT_APP_WEBB_SITE_NAME}{' • '}{process.env.REACT_APP_WEBB_SITE_LINE}</title>
        <link rel='canonical' href={process.env.REACT_APP_WEBB_SITE_LINK} />
      </Helmet>

      <ContentFormat
        name={metadata.name}
        media={{ size: 'xtra', show: false, data: <></> }}
        header={{
          header: (
            <>
              <Link to={`${asset.role}/home`}>
                <WebbIcon data={{ color: 'text-color-main', size: 'text-icon-sm' }}/>
              </Link>
            </>
          ),
          data: ( <>{' '}<WebbHeader />{' '}</> ),
          footer: ( <> <UserAvatar /> </> )
        }}
        sidebar={{
          header: (<> <p className='text-normal text-bold m-0'>{metadata.name}</p> </>),
          data: (
            <> <UnitHeaderModule unit={id} data={handleData} /> </>
          ),
          footer: <>...</>
        }}
        content={{
          header: ( <> <p className='m-0'>Asset Data</p> </> ),
          data: (
            <>
              <h2 className='text-normal text-color-next mb-3'>Overview</h2>
              <UnitStatisticsModule data={data?.units || {}}/>
              
              <WebbDividerMedium />
              <h2 className='text-normal text-color-next mb-3'>Audience</h2>
              <UnitRatesModule data={data && data?.rates || []}/>

              <WebbDividerMedium />
              <h2 className='text-normal text-color-next mb-3'>Transfers</h2>

              <WebbDividerSmall />
              <div className='d-flex'>
                <div>
                  <button onClick={()=>setTransferType("token")} className={`btn rounded-xx h-100  btn-${transferType=="token"?'primary':'light'}   text-small `}>Tokens</button>
                </div>
                <div>
                  <button onClick={()=>setTransferType("data")} className={`btn rounded-xx  mx-3 h-100 btn-${transferType=="data"?'primary':'light'}   text-small `}>Data</button>
                </div>

              </div>

              <WebbDividerSmall />
              {
              transferType=="token"? 
              <>
                <UnitTransfersListModule data={{}} />
              </>:
              <></>
              }

              {
              transferType=="data"? 
              <>
                <UnitTransfersDataListModule data={{}} />
              </>:
              <></>
              }

              
              
              

              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
              <WebbDividerMedium />
            </>
          )
        }}
        actionbar={{
          header: <>Your Actions</>,
          data: (
            <>
              <UnitActionsModule data={data || {}}/>

              <div className='mb-2'></div>
              <UnitStatusModule data={data?.status || {}} />

              <div className='mb-2'></div>
              <UnitCodeModule data={{id: id || ''}}/>

            </>
          ),
          footer: <></>
        }}
      ></ContentFormat>
    </>
  )
}
