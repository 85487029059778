// init
import axios from 'axios'
import services from '../data.services/data-services-main.json'
import { encrypt, decrypt } from "./srvc-encr-node";

const base = services.data.find(x => x.code == 'transfers.assets').link

// -----------------

export const TransfersAssetDetails = async item => {
  const basx = base + '/transfers/assets/details'
  const head = { 
    "Content-Type": "application/json",
          "Authorization": item.client

  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersAssetList = async item => {

  const basx = base + '/transfers/assets/list'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item.client

  }
  const datx = { data: item.data, srvc: item.service }
  const payload = await encrypt(datx,item.secret)

  var result
  try {
    result = await axios.post(basx,{payload} , { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    return { code: result.status, stat: result.data.stat, data }
    } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersAssetListAsset = async item => {

  const basx = base + '/transfers/assets/list/asset'
  const head = { 
    "Content-Type": "application/json",
          "Authorization": item.client

  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersAssetListUnit = async item => {
  // console.log(item)
  const basx = base + '/transfers/assets/list/unit'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item.client

  }
  const datx = { data: item.data, srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC }
  
  const payload = await encrypt(datx,item.secret)

  var result
  try {
    result = await axios.post(basx, {payload}, { headers: head })
    const data = await decrypt(result.data.data,item.secret)
    // console.log(data)
    return { code: result.status, stat: result.data.stat, data }  
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersAssetListUser = async item => {

  const basx = base + '/transfers/assets/list/users'
  const head = { 
    "Content-Type": "application/json",
    "Authorization": item.client

  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersAssetListUserWait = async item => {

  const basx = base + '/transfers/assets/list/users/wait'
  const head = { 
    "Content-Type": "application/json",
          "Authorization": item.client

  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}

export const TransfersAssetClaim = async item => {

  const basx = base + '/transfers/assets/claim'
  const head = {
    "Content-Type": "application/json",
    Authorization: item.client,
  };
  const datx = { data: item.data, srvc: item.service };
  const payload = await encrypt(datx, item.secret);

  var result;
  try {
    result = await axios.post(basx, { payload }, { headers: head });
    const data = await decrypt(result.data.data, item.secret);
    return { code: result.status, stat: result.data.stat, data };
  } catch (error) {
    return { code: error.response.status, ...error.response.data };
  }
}


export const TransfersAssetSubmit = async item => {

  const basx = base + '/transfers/assets/submit'
  const head = { 
    "Content-Type": "application/json",
          "Authorization": item.client

  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersAssetDecline = async item => {

  const basx = base + '/transfers/assets/decline'
  const head = { 
    "Content-Type": "application/json",
          "Authorization": item.client

  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}


export const TransfersAssetCreate = async item => {

  const basx = base + '/transfers/assets/create'
  const head = { 
    "Content-Type": "application/json",
          "Authorization": item.client

  }
  const datx = {
    data: item.data, 
    srvc: item?.service || process.env.REACT_APP_WEBB_SITE_SRVC
  }

  var result
  try {
    result = await axios.post(basx, datx, { headers: head })
    return { code: result.status, ...result.data }
  } catch (error) {
    return { code: error.response.status, ...error.response.data }
  }
}
