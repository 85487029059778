// auth - firebase mail link
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { NumberFormat } from '../../services/srvc-utilities'

import WebbDividerSmall from '../webx/webb-divider-sm'
import WebbDividerMedium from '../webx/webb-divider-md'

import NavsButtonBack from '../webx/navs-button-back'
import NavsButtonNext from '../webx/navs-button-next'

import { GetUserForm } from '../../services/srvc-utilities'
import { GetLocalUser, GetLocalBusiness } from '../../services/srvc-auth-local'

import { TransfersAssetListUnit } from '../../services/srvc-transfers-assets-realm'

import listStatus from '../../data.static/data-transfers-status.json'

export default function UnitTransfersDataListModule (props) {

  const usxx = GetUserForm()
  const usrx = GetLocalUser()
  const temx = GetLocalBusiness()
  const asset = usxx === 'user' ? usrx : temx

  const navigate = useNavigate()
  const {id} = useParams()

  const [loader, setLoader] = useState(true)
  const [data, setData] = useState([])
  const [list, setList] = useState(listStatus.data)

  const [search, setSearch] = useState()

  const [index, setIndex] = useState(1)
  const [items, setItems] = useState(10)

  const [curr, setCurrentIndex] = useState(1)
  const [next, setNextIndex] = useState()
  const [last, setLastIndex] = useState()

  const [count, setCount] = useState()
  const [total, setTotal] = useState()

  const [text, setText] = useState('')

  useEffect(() => {
    if (asset) {
      const fetchData = async () => {
        setLoader(true)
        
        // const datx = { unit: id, user: asset.item, index: index.toString(), items: items.toString() }
        // const result = await TransfersAssetListUnit({
        //   data: datx, 
        //   service: asset?.creds?.service,
        //   client:asset?.creds?.client,
        //   secret:asset?.creds?.secret
        // })

        // if (result.stat) {
        //   // && resAssets.stat
        //   setData(result.data.list)

        //   const resTotal = result.data.count //+resAssets.data.count
        //   setTotal(result.data.count)

        //   setText(
        //     `${(index - 1) * items + 1} - ${
        //       index * items < resTotal ? index * items : resTotal
        //     } of ${resTotal}`
        //   )
        // }

        setLoader(false)
      }
      fetchData()
    } else {
    }
  }, [props.search, index, items])

  const NextIndex = async () => {
    if (data.length < items) {
    } else {
      setNextIndex(curr + 1)
      setIndex(curr + 1)
      setCurrentIndex(curr + 1)
    }
  }

  const LastIndex = async () => {
    if (index == 1) {
    } else {
      setLastIndex(curr - 1)
      setIndex(curr - 1)
      setCurrentIndex(curr - 1)
    }
  }

  // console.log(data)

  // if (loader) return <>
  //   <div className='p-3 back-color-wite rounded-xd border align-middle' style={{}}>

  //     <span className="align-middle text-lead">
  //       <i className="bx bxs-info-circle text-color-wait"></i>
  //     </span>
  //     <span className='ms-1 text-color-tone'>Please Wait...</span>

  //   </div>
  // </>
  if (!loader && (!data || data.length === 0))
    return (
      <>
        <div
          className='p-3 back-color-wite rounded border align-middle'
          style={{}}
        >
          <span className='align-middle text-lead'>
            <i className='bx bxs-info-circle text-color-tint'></i>
          </span>
          <span className='ms-1 text-color-tone'>No Data</span>
        </div>
      </>
    )

  return (
    <>
      <div className='back-color-wite border rounded'>
      {/* header */}
      <div className="border-bottom text-small text-bold">
        <div className="d-flex p-2 px-3">

          <div className="" style={{width: '5%'}}>         
            <p className="m-0">
              <span className="text-small">
                <i className="bx bxs-grid-alt text-color-tint"></i>
              </span>
            </p>
          </div>  

          <div className="" style={{minWidth: '55%'}}>
            <p className="m-0">
              <span className="">{'User'}</span>
            </p>
          </div>    

          <div className="" style={{width: '20%'}}>         
            <p className="m-0 text-sm">
              <span className="">{'Status'}</span>
            </p>
          </div>  

          <div className="ms-auto text-end text-sm" style={{width: '20%'}}>         
            <p className="m-0 text-sm ">
              <span className="d-none d-md-block">{'Amount'}</span>
              <span className="d-md-none">{'Status'}</span>
            </p>
          </div>

      </div>
      </div>

      {/* data */}
      {data && data.map((item, i) => (
      <div key={i}>
        <div className="d-flex p-2 px-3">
        
          <div className="" style={{width: '5%'}}>         
            <p className="m-0">
              <span className="text">
                <i className="bx bxs-image text-color-tint"></i>
              </span>
            </p>
          </div>  

          <div className="" style={{width: '55%'}}>
            <p className="m-0">
              <span className="text-bold">{item.user.name}</span>
            </p>
            <p className="m-0 text-small d-none">{item?.meta?.memo || '******'}</p>
            <p className="m-0 text-small">
              <span>{(new Date(parseInt(item?.created))).toLocaleString() || '******'}</span>
            </p>
          </div>
          <div className="" style={{width: '20%'}}>
            <span className={`p-1 rounded text-mini text-uppercase text-color-wite back-${list?.find(x => x.code == item.status).color}`}>
              {list?.find(x => x.code == item.status).memo}
            </span>
          </div>
          <div className="ms-auto text-end" style={{width: '20%'}}>
            <p className="m-0">
              <span className="ms-1">{item.mode =='credit' ? '+' : '-'}</span>
              <span className="">{NumberFormat((item?.size?.number || '0'))}</span>
              <span className="ms-1 d-none"></span>
              <span className="text-mini d-none">{item.size.ticker || ''}</span>
            </p>
          </div>
        </div>
        <div className={i < data.length-1 ? 'border-bottom': ''}></div>
      </div>
      ))}

      </div>

      {/* navs */}
      <WebbDividerSmall />
      <div className={data.length < items ? '' : ''}>
        <div className='d-flex justify-content-between'>
          <div className='' onClick={() => LastIndex()}>
            <NavsButtonBack />
          </div>

          <div className=''>
            <p className='mt-2 text-small'>{text}</p>
          </div>

          <div className='' onClick={() => NextIndex()}>
            <NavsButtonNext />
          </div>
        </div>
      </div>
    </>
  )
}
